import PropTypes from 'prop-types';
import React, {Component} from 'react';
import styled from 'styled-components';

import { formControlFont } from '../../style-utils.js';
import theme from '../../theme';


const StyledTextfield = styled.div`
	margin: 25px 0 0;
	position: relative;

	input, label {
		line-height: 1.3rem;
	}

	input[type="text"]{
		font: 15px/24px 'Muli', sans-serif;
		${formControlFont}
		color: #333;
		width: 100%;
		box-sizing: border-box;
		outline: none;
		z-index: 2;
		background: #fff;
	}

	:focus{outline: none;}

	.effect-20{
		border: 1px solid #ccc;
		padding: 7px 14px;
		transition: 0.4s;
		background: transparent;
	}

	.effect-20 ~ .focus-border:before,
	.effect-20 ~ .focus-border:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 2px;
		background-color: ${props => theme.colors.blue};
		transition: 0.3s;
	}
	.effect-20 ~ .focus-border:after{
		top: auto;
		bottom: 0;
		left: auto;
		right: 0;
	}
	.effect-20 ~ .focus-border i:before,
	.effect-20 ~ .focus-border i:after{
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 2px;
		height: 0;
		background-color: ${props => theme.colors.blue};
		transition: 0.4s;
	}
	.effect-20 ~ .focus-border i:after{
		left: auto;
		right: 0;
		top: auto;
		bottom: 0;
	}
	.effect-20:focus ~ .focus-border:before,
	.effect-20:focus ~ .focus-border:after,
	.has-content.effect-20 ~ .focus-border:before,
	.has-content.effect-20 ~ .focus-border:after{
		width: 100%;
		transition: 0.3s;
	}
	.effect-20:focus ~ .focus-border i:before,
	.effect-20:focus ~ .focus-border i:after,
	.has-content.effect-20 ~ .focus-border i:before,
	.has-content.effect-20 ~ .focus-border i:after{
		height: 100%;
		transition: 0.4s;
	}
	.effect-20 ~ label{
		position: absolute;
		left: 14px;
		width: 100%;
		top: 10px;
		color: #aaa;
		transition: 0.3s;
		z-index: 1;
		${formControlFont}
	}
	.effect-20:focus ~ label,
	.has-content.effect-20 ~ label{
		top: -18px;
		left: 0;
		font-size: 12px;
		${formControlFont}
		color: ${props => theme.colors.blue};
		transition: 0.3s;
	}
`
class Textfield extends Component {
 	constructor(props) {
		super(props);
		this.state = {
			hasContent: false
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange (event) {
		this.setState({
			hasContent: !!event.target.value
		});
	}

	render () {
		const {name, label} = this.props;
		return (
			<StyledTextfield>
				<input
				 	autoComplete="off"
					className={'effect-20 ' + (this.state.hasContent ? 'has-content' : '')}
					id={name}
					name={name}
					onChange={this.handleChange}
					placeholder=""
					type="text"
				/>
				<label htmlFor={name}>{label}</label>
				<span className="focus-border">
					<i></i>
				</span>
			</StyledTextfield>
		);
	}
}


Textfield.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
};

export default Textfield;
