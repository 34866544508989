import styled from 'styled-components'
import { space, width, color } from 'styled-system'

export const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	${width}
	${space}
	${color}
	margin: 0 -40px;
`;

export const Col = styled.div`
	padding: 0 40px;
	${width}
	${space}
	${color}
	line-height: 1.75rem;
	margin-bottom: 1.45rem;
`;