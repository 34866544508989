import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import { selectStyles } from '../../style-utils.js';


const StyledSelect = styled.div`
	/*.react-select__control {
		box-shadow: none !important;
	}*/
	color: #333;
`


const CustomSelect = (props) => {
	return (
		<StyledSelect>
			<Select
				classNamePrefix="react-select"
				styles={selectStyles}
				options={props.options}
				defaultValue={props.defaultValue}
			/>
		</StyledSelect>
	);
}


CustomSelect.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		})
	).isRequired,
	defaultValue: PropTypes.shape({
		value: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
	})
};

export default CustomSelect;
