import React from 'react';
import styled from 'styled-components';

import { latoBold } from  '../../style-utils.js';


const StyledButton = styled.button`
	display: inline-block;
    ${latoBold}
    color: ${props => props.color};
    cursor: pointer;
    background-color: transparent;
    border: 2px solid ${props => props.color};
    display: inline-block;
    padding: 12px 36px;
    font-size: .625rem;
    text-decoration: none;
	transition: 0.1s all linear;
	line-height: 1.1rem;

	&:hover {
		background-color: ${props => props.color};
		color: ${props => props.hoverColor};
	}
`;

const Button = (props) => {
	const { color, children, hoverColor, type } = props;
	return (
		<StyledButton
			color={color || '#000'}
            hoverColor={hoverColor || '#fff'}
            type={type || 'button'}
		>
			{children}
		</StyledButton>
	);
}

export default Button;
